import React, {memo, useContext, useEffect, useRef} from 'react';
import Link from 'next/link';
import {useState} from 'react';
import {useMeasure} from 'react-use';
import {Box, Button, ButtonBase} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import theme from "../../theme";
import {useTranslation} from "../../../tools/i18n";
import urlCorrection from "../../../tools/urlCorrection";
import {TrackingContext} from "../../providers/tracking";

const useStyles = makeStyles(() => ({
	item: {
		minHeight: 50
	},
	button: {
		width: '100%',
		justifyContent: 'flex-start',
		padding: '14px 8px 14px 12px',
		minHeight: 50,
		color: '#555',
		fontSize: 13,
		fontFamily: theme.typography.fontFamily
	},
}), {name: 'MenuItem'});

function MenuItem({children, item, level, parent, classes, colorOverride = false}) {
	const internalClasses = useStyles();
	const {actions: trackingActions} = useContext(TrackingContext);
	const [open, setOpen] = useState(false);
	const [childOpen, setChildOpen] = useState(false);
	const { t } = useTranslation('general');
	const itemRef = useRef();

	useEffect(() => {
		document.addEventListener('childOpen', handleChildOpen);
		document.addEventListener('childClosed', handleChildClosed);

		return () => {
			document.removeEventListener('childOpen', handleChildOpen);
			document.removeEventListener('childClosed', handleChildClosed);
		}
	}, []);

	function handleChildOpen(event) {
		if(event.detail.parentId === item.id) {
			setChildOpen(true);
		}
	}

	function handleChildClosed(event) {
		if(event.detail.parentId === item.id) {
			setChildOpen(false);
		}
	}

	function openMenu(e) {
		e.preventDefault();

		const event = new CustomEvent("childOpen", {detail: {parentId: parent.id}});
		document.dispatchEvent(event);

		setOpen(true);
	}

	function closeMenu(e) {
		e.preventDefault();
		e.stopPropagation();

		const event = new CustomEvent("childClosed", {detail: {parentId: parent.id}});
		document.dispatchEvent(event);

		setOpen(false);
	}

	function renderList(children, parent, level) {
		if (children.length > 0) {
			let seeAllUrl = parent.url
			let christmasInspirationUrl = ''
			if (parent.name === 'Christmas Shop') {
				seeAllUrl = parent.children[0].url
				christmasInspirationUrl = parent.url
			}
			return (
				<ul className={[classes.subList, 'level-' + level].join(' ')}>
					{parent.name === 'Christmas Shop' && parent.url && parent.type !== 'folder' ? <li className={[classes.item, internalClasses.item].join(' ')}>
						<Link as={urlCorrection(christmasInspirationUrl)} href="/[[...slug]]">
							<a onClick={() => trackingActions.click({type: 'menu_link', text: 'Inspiration', identifier: urlCorrection(parent.url)})}>
								<ButtonBase className={internalClasses.button} style={{color: colorOverride}}>
									{t('inspiration')}
								</ButtonBase>
							</a>
						</Link>
					</li> : '' }

					{parent.url && parent.type !== 'folder' ? <li className={[classes.item, internalClasses.item].join(' ')}>
						<Link as={urlCorrection(seeAllUrl)} href="/[[...slug]]">
							<a onClick={() => trackingActions.click({type: 'menu_link', text: 'see all', identifier: urlCorrection(parent.url)})}>
								<ButtonBase className={internalClasses.button} style={{color: colorOverride}}>
									{t('see-all')}
								</ButtonBase>
							</a>
						</Link>
					</li> : '' }

					{children}
				</ul>
			);
		} else {
			return ('');
		}
	}

	function renderIcon() {
		if (children.length > 0 && !open) {
			return (
				<svg className="indicator" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
					 stroke="currentColor" >
					<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7"/>
				</svg>
			);
		} else {
			return ('');
		}
	}

	function getItemId() {
		if (item.url) {
			let urlArray = item.url.split('/');

			return urlArray[urlArray.length - 1];
		}

		return "";

	}

	return (
		<li className={[classes.item, internalClasses.item, open ? 'opened' : '', childOpen ? 'childOpen' : '', 'level-' + level, getItemId()].join(' ')} ref={itemRef}>
			<Link as={urlCorrection(item.url)} href="/[[...slug]]">
				<ButtonBase className={internalClasses.button} style={{color: colorOverride}} onClick={(e) => {
					if(item.children && item.children.length > 0) {
						trackingActions.click({type: 'menu_link_open', text: item.name, identifier: urlCorrection(item.url)});

						if(!open) {
							openMenu(e);
						} else {
							closeMenu(e);
						}
					} else {
						trackingActions.click({type: 'menu_link', text: item.name, identifier: urlCorrection(item.url)});
					}
				}}>
					{open ?
						<svg className="back" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
							 stroke="currentColor" onClick={closeMenu}>
							<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7"/>
						</svg> : ''}
					{item.name}
					{renderIcon()}
				</ButtonBase>
			</Link>

			{renderList(children, item, level)}
		</li>
	);
}

export default memo(MenuItem);
