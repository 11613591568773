import { useContext, useEffect, useState, forwardRef } from 'react';
import {makeStyles} from '@material-ui/styles';
import {
	Badge,
	Modal,
	ClickAwayListener,
	TextField,
	Button,
	CircularProgress
} from '@material-ui/core';
import Like from "../../public/images/icons/heart-ultrathin.svg";
import { SessionContext } from "../providers/session";
import theme from "../theme";
import { Add, Remove } from "@material-ui/icons";
import { useTranslation } from "tools/i18n";
import Link from "next/link";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AccountIcon from "../../public/images/icons/my-newport.svg";
import WeddingIcon from "../../public/images/icons/wedding.svg";
import { useRouter } from "next/router";

const styles =  {
	wrapper: {
		display: 'flex',
		gridColumn: '3/3',
		gridRow: '1/1',
		marginLeft: 'auto',
		marginRight: 40,
	},
	trigger: {
		padding: 10,
		height: 40,
		cursor: 'pointer',
		'&.myNewport': {
			marginRight: 0,
			[theme.breakpoints.down('sm')]: {
				position: 'absolute',
				top: 16,
				left: 90,
			}
		},
		'&.wishlist': {
			marginRight: 0
		},
		[theme.breakpoints.down('sm')]: {
			marginRight: -10,
		}
	},
	icon: {
    	display: 'block',
		width: 20,
    	height: 20,
		color: '#000000',
		strokeWidth: 0.5,
		fill: 'transparent'
	},
	badge: {
		verticalAlign: 'initial'
	},
	count: {
		color: theme.palette.primary.background,
		padding: '0 4px',
		fontSize: 11,
		backgroundColor: '#000',
		'.xmas &': {
			background: theme.palette.primary.background,
			color: '#000'
		}
	},
	centerModal: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		padding: 10,
		minWidth: 250,
		backgroundColor: theme.palette.primary.background,
	},
	pleaseLogin: {
		maxWidth: 500,
		'& .title': {
			margin: '5px 0 5px',
			fontSize: 14,
    		fontFamily: '"Futura", "Helvetica", "Arial", sans-serif',
    		fontWeight: 600
		},
		'& .actions': {
			display: 'flex',
			flexWrap: 'wrap',
			alignItems: 'center'
		},
		'& .button': {
			padding: '8px 14px',
    		alignItems: 'center',
    		fontSize: 14,
			color: theme.palette.primary.background,
			textTransform: 'none',
    		backgroundColor: theme.palette.primary.purchase.background,
    		hover: theme.palette.primary.purchase.hover,
			borderRadius: 0
		}
	},
	wishlistModal: {
		position: 'fixed',
		top: '6%',
		left: '50%',
		width: '100%',
		padding: 15,
		transform: 'translate(-50%, 0%)',
		'&:focus': {
			outline: 'none'
		},
		'& $list': {
			position: 'relative',
			top: 'auto',
			right: 'auto'
		}
	},
	selectList: {
		display: 'flex',
		flexDirection: 'column',
		maxWidth: 250,
		width: '100%',
		'& > p': {
			margin: '5px 0 5px',
			fontSize: 14,
    		fontFamily: '"Futura", "Helvetica", "Arial", sans-serif',
    		fontWeight: 600
		},
		'& $list': {
			position: 'relative',
			top: 'auto',
			right: 'auto',
			minWidth: 0,
			width: '100%',
			borderRadius: 0
		}
	},
	list: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		position: 'absolute',
		top: '85%',
		zIndex: 100,
		right: 40,
		minWidth: 280,
		'&.main': {
			padding: '10px 15px',
			backgroundColor: '#fafafa',
			'.xmas &': {
				color: '#000 !important'
			},
			'& $item': {
				backgroundColor: '#fafafa'
			}
		},
		'&.select': {
			top: 0,
			right: 0,
		}
	},
	notLoggedIn: {
		display: 'flex',
		'& span': {
			marginTop: 10,
		},
		'& a': {
			fontWeight: 500,
			color: 'inherit'
		}
	},
	item: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
		padding: '5px 8px',
		marginTop: 3,
		fontSize: 14,
		cursor: 'pointer',
		backgroundColor: '#f0f0f0',
		'&:hover': {
			backgroundColor: '#fafafa'
		},
		'& .name': {
			marginLeft: 6,
			marginRight: 'auto',
			lineHeight: '22px',
			color: 'inherit'
		},
		'& .count': {
			marginLeft: 10,
			fontWeight: 500
		},
		'& .title': {
			display: 'flex',
			'.xmas &': {
				color: '#000 !important'
			},
		},
		'&.active': {
			fontWeight: 600,
		},
		'&.new': {
			alignItems: 'center',
			width: 'auto',
			padding: '4px 6px',
			fontSize: 12,
			backgroundColor: theme.palette.primary.main + '!important',
			color: theme.palette.primary.background,
			cursor: 'pointer',

		},
		'.xmas &.new': {
			backgroundColor: theme.palette.primary.main + '!important',
			color: '#fff !important',
		},

		'&.empty': {
			color: '#555',
			fontSize: 12,
			backgroundColor: '#eaeaea !important'
		},
		'& a': {
			color: 'inherit'
		}
	},
	active: {
		'& .name': {
			fontWeight: 500,
		}
	},
	activeIcon: {
		marginRight: 5,
		fontSize: '1rem',
		cursor: 'pointer'
	},
	actionWrapper: {
		display: 'flex',
		width: '100%',
		justifyContent: 'space-between',
		alignItems: 'center',
		gap: 8,
		marginTop: 10,
		'& > span ': {
			marginLeft: 'auto',
		},
		'& > span a': {
			color: 'inherit',
            fontSize: 12
		}
	},
	addToList: {
		marginLeft: 'auto',
		marginRight: -4,
		width: 36,
		minWidth: 0,
		padding: 5,
		borderRadius: 0,
		'& svg': {
			fontSize: 18,
			color: '#11284a'
		},
	},
	add: {
		marginRight: 8,
		fontSize: 16
	},
	createWishlist: {
		position: 'fixed',
		padding: '30px 20px 20px',
		maxWidth: 400,
		width: '100%',
		'&:focus': {
			outline: 'none'
		},
		'& p': {
			margin: '0 0 15px',
			fontSize: 13,
    		fontWeight: 500,
    		textTransform: 'uppercase',
    		letterSpacing: 1
		}
	},
	createWishlistActions: {
		display: 'flex',
		gap: 10
	},
	createNameInput: {
		borderRadius: 0
	},
	createButton: {
		height: 40,
		color: `${theme.palette.primary.background} !important`,
		padding: '8px 16px',
		fontSize: 12,
    	fontWeight: 600,
        letterSpacing: 2,
		backgroundColor: theme.palette.primary.main,
		borderRadius: 0,
		'&:hover': {
			backgroundColor: theme.palette.primary.main
		}
	},
	additional: {
		padding: 15,
		marginTop: 10,
		border: '1px solid #f0f0f0',
		'& $createNameInput': {
			marginBottom: 15
		}
	},
	isWishlistToggle: {
		display: 'flex',
		alignItems: 'center',
		marginTop: 8
	},
	weddingToggle: {
		marginLeft: -12
	},
	weddingToggleChecked: {
		color: '#000 !important'
	},
	weddingLabel: {
		marginTop: 4,
		color: '#000',
		fontSize: 14,
		'& a': {
			color: 'inherit'
		}
	},
	buyButton: {
		...theme.button.purchase,
		backgroundColor: theme.palette.primary.purchase.background,
		padding: '14px 12px',
		minWidth: 0,
		flexGrow: 0,
		fontSize: 10,
		textTransform: 'uppercase',
		'&:hover': {
			...theme.button.purchaseHover
		},
		'&:before': {
			content: '""',
			height: 1,
			backgroundColor: theme.palette.primary.background,
			display: 'block',
			position: 'absolute',
			top: 4,
			left: 0,
			right: 0,
			transition: 'all 0.25s ease'
		},
		'&:after': {
			content: '""',
			height: 1,
			backgroundColor: theme.palette.primary.background,
			display: 'block',
			position: 'absolute',
			bottom: 4,
			left: 0,
			right: 0,
			transition: 'all 0.25s ease'
		},
	},
	weddingListAdd: {
		maxHeight: 58,
		marginTop: 16,
		marginBottom: 0,
		backgroundColor: '#a39161',
	},
}

const useStyles = makeStyles(() => styles);

export default function Favorites() {
	const router = useRouter();
	const classes = useStyles();
	const {t} = useTranslation();
	const { config, wishlists, wishlistProducts, weddingList, loggedIn, actions: { createNewWishlist, addOrRemoveProductLike } } = useContext(SessionContext);
	const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
	const [open, setOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [selectedWishlistOpen, setSelectedWishlistOpen] = useState(false);
	const [newListOpen, setNewListOpen] = useState(false);
	const [notLoggedInPopup, setNotLoggedInPopup] = useState(false);
	const [wishlistName, setWishlistName] = useState('');
	const [wishlistType, setWishlistType] = useState('standard');

	useEffect(() => {
		let path = router.asPath;

		if(path.indexOf('#create-wishlist') >= 0) {
			if (path.indexOf('xmas') >= 0) {
				setWishlistType('christmas')
			}

			if (loggedIn) {
				setNewListOpen(true);
			} else {
				router.replace(router.asPath.split('#')[0]);
				router.push(config.myNewport + '/account/lists');
			}
		} else {
			setNewListOpen(false);
		}

		setNotLoggedInPopup(false);
	}, [router.asPath]);

	useEffect(async () => {
		if(!newListOpen && router.asPath.indexOf('#create-wishlist') >= 0) {
			await router.replace(router.asPath.split('#')[0]);
		}
	}, [newListOpen]);

	// Temp storage
	const [tempWishlistToggle, setTempWishlistToggle] = useState(false);

	useEffect(() => {
		window.addEventListener('toggle-wishlist-product', handleToggleWishlistProduct);
		window.addEventListener('toggle-not-logged-in-popup', handleNotLoggedInPopup);

		return () => {
			window.removeEventListener('toggle-wishlist-product', handleToggleWishlistProduct);
			window.removeEventListener('toggle-not-logged-in-popup', handleNotLoggedInPopup);
		}
	}, [wishlists]);

	useEffect(() => {
		let listCount = wishlists.length + (weddingList ? 1 : 0);
		if(selectedWishlistOpen && listCount === 1) {
			let wishlistId = wishlists.length === 1 ? wishlists[0].id : weddingList.id;

			addItemToSelectedWishlist(wishlistId);
			setSelectedWishlistOpen(false);
		}
	}, [selectedWishlistOpen]);

	function handleNotLoggedInPopup() {
		setNotLoggedInPopup(true);
	}

	function handleToggleWishlistProduct(event) {
		const {
			detail: {
				productId
			}
		} = event;

		setTempWishlistToggle(productId);
		setSelectedWishlistOpen(true);
	}

	function addItemToSelectedWishlist(wishlistId) {
		let isActive = wishlistProducts.filter((item) => item.product_id === tempWishlistToggle && item.wishlist_id == wishlistId).length > 0;

		if(!isActive && weddingList) {
			isActive = weddingList.items.filter((item) => item.product.id === tempWishlistToggle).length > 0;
		}

		if(isActive) {
			addOrRemoveProductLike(tempWishlistToggle, wishlistId, 'remove');
		} else {
			addOrRemoveProductLike(tempWishlistToggle, wishlistId, 'add');
		}

		setTempWishlistToggle(false);
	}

	async function onCreate() {
		if(wishlistName.length <= 0) {
			return;
		}

		setIsLoading(true);

		let wishlistObject = {
			name: wishlistName,
			type: wishlistType
		}

		let response = await createNewWishlist(wishlistObject);

		if(response.status === 'success') {
			setNewListOpen(false);
		}

		setIsLoading(false);
	}

	function renderWishlists() {
		return <div className={[classes.list, 'main', wishlists.length === 0 ? 'empty' : '', theme.mode].join(' ')}>
			{wishlists.map((wishlist) => {
				let itemCount = wishlist?.items?.length ? wishlist?.items?.length : (wishlist.items_count || 0);

				return <article key={wishlist.id} className={[classes.item].join(' ')}>
					<Link href={config.myNewport + '/account/lists/' + wishlist.id}>
						<a className={'name'}>{wishlist.name}</a>
					</Link>
					<span className={'count'}>({itemCount})</span>
				</article>;
			})}

			{wishlists.length === 0 ? <article className={[classes.item, 'empty'].join(' ')}>
				<div>{t('no-wishlist')}.</div>
			</article> : ''}

			<div className={[classes.actionWrapper, theme.mode].join(' ')}>
				<span dangerouslySetInnerHTML={{__html: t('wishlist-howto')}}/>
				<div className={[classes.item, 'new'].join(' ')} onClick={() => setNewListOpen(true)} style={{display: wishlists.length > 9 ? 'none' : 'flex'}}>
					<span className={'name'}>{t('new-wishlist')}</span>
					<Add className={classes.add}/>
				</div>
			</div>
		</div>;
	}

	return (
		<>
			<div className={[classes.wrapper, theme.mode].join(' ')}>
				{config.myNewport ? <a href={config.myNewport} className={[classes.trigger, 'myNewport'].join(' ')}>
					<AccountIcon width={20} className={classes.icon}/>
				</a> : ''}

				{config.myNewport ? (!loggedIn ? <Link href={t('wishlist-link')}>
						<a className={[classes.trigger, 'wishlist'].join(' ')}>
							<Badge badgeContent={0} classes={{ root: classes.badge, badge: classes.count }}>
								<Like width={20} className={classes.icon}/>
							</Badge>
						</a>
					</Link> :
					<div className={[classes.trigger, 'wishlist'].join(' ')} onClick={(event) => {
						event.preventDefault();
						event.stopPropagation();
						setOpen(!open);
						return false;
					}}>
						<Badge badgeContent={wishlistProducts.length}
							   classes={{ root: classes.badge, badge: classes.count }}>
							<Like width={20} className={classes.icon}/>
						</Badge>
					</div>) : ''}

				{open && loggedIn ? (
					!isDesktop ? <Modal open={open} onClose={() => setOpen(false)}>
							<div className={[classes.wishlistModal, theme.mode].join(' ')}>{renderWishlists()}</div>
						</Modal> :
						<ClickAwayListener onClickAway={() => setOpen(false)}>{renderWishlists()}</ClickAwayListener>
				) : ''}

				{config.myNewport && config.language.locale === 'sv' ? (
					weddingList ? <a href={`/inspiration/wishlist`} className={[classes.trigger, 'wedding'].join(' ')}>
						<WeddingIcon width={20} className={classes.icon}/>
					</a> : <a href={`/inspiration/wishlist`} className={[classes.trigger, 'wedding'].join(' ')}>
						<WeddingIcon width={20} className={classes.icon}/>
					</a>
				) : ''}

				{!loggedIn ? <>
					<Modal open={notLoggedInPopup}>
						<>

						<ClickAwayListener onClickAway={() => setNotLoggedInPopup(false)}>
							<div className={[classes.centerModal, classes.pleaseLogin, theme.mode].join(' ')}>
								<p className={'title'}>{t('login-required-title')}</p>
								<p>{t('login-required-text')}</p>

								<div className={'actions'}>
									<Link href={`${config.myNewport}/account/lists`}>
										<a>
											<Button className={'button'}>{t('login-button')}</Button>
										</a>
									</Link>
									<span style={{marginLeft: 15}} dangerouslySetInnerHTML={{__html: t('wishlist-howto')}}></span>
								</div>
							</div>
						</ClickAwayListener>
						</>
					</Modal>
				</> : ''}

				<Modal open={newListOpen} onClose={() => setNewListOpen(false)}>
					<div className={[classes.createWishlist, classes.centerModal, theme.mode].join(' ')}>
						<p>{t('new-wishlist-title')}</p>
						<div className={classes.createWishlistActions}>
							<TextField
								variant={'outlined'}
								size={'small'}
								label={t('new-wishlist-name')}
								InputProps={{className: classes.createNameInput}}
								onChange={(event) => setWishlistName(event.target.value)}
							/>
							<Button className={classes.createButton} onClick={() => onCreate()} disabled={isLoading}>
								{t('new-wishlist-button')}
								{isLoading ? <CircularProgress color={theme.palette.primary.background} size={18} style={{marginLeft: 6}}/> : ''}
							</Button>
						</div>
					</div>
				</Modal>

				<Modal open={selectedWishlistOpen} onClose={() => setSelectedWishlistOpen(false)}>
					<div className={[classes.selectList, classes.centerModal, theme.mode].join(' ')}>
						<p>{t('add-to-wishlist')}</p>
						<div className={[classes.list, 'select'].join(' ')}>
							{wishlists.map((wishlist) => {
								let isActive = wishlistProducts.filter((item) => item.product_id === tempWishlistToggle && item.wishlist_id == wishlist.id).length > 0;
								let itemCount = wishlist?.items?.length ? wishlist?.items?.length : (wishlist.items_count || 0);

								return <article className={[classes.item, isActive ? 'active' : ''].join(' ')}
												onClick={() => {
													addItemToSelectedWishlist(wishlist.id);
													setSelectedWishlistOpen(false);
												}}>
									<div className={'title'}>
										{wishlist.name}
									</div>
									<span className={'count'}>({itemCount})</span>
									<Button className={classes.addToList}>{isActive ? <Remove/> : <Add/>}</Button>
								</article>;
							})}

							{wishlists.length === 0 ? <p style={{
								margin: '2px 0',
								padding: 8,
								width: '100%',
								backgroundColor: '#f0f0f0'
							}}>{t('no-wishlist')}</p> : ''}

							<div className={[classes.item, 'new'].join(' ')} onClick={() => setNewListOpen(true)}
								 style={{ display: wishlists.length > 9 ? 'none' : 'flex', width: '100%' }}>
								<span className={'name'}>{t('new-wishlist')}</span>
								<Add className={classes.add}/>
							</div>
						</div>

						{weddingList ? <>
							<Button
								className={[
									classes.buyButton,
									classes.weddingListAdd
								].join(' ')}
								classes={{ disabled: classes.buyButton, label: classes.purchaseButtonLabel }}
								onClick={(e) => {
									addItemToSelectedWishlist(weddingList.id);
									setSelectedWishlistOpen(false);
								}}>
								{ weddingList.items.filter((item) => item?.product?.id === tempWishlistToggle)?.length > 0 ? t('remove-from-wedding-list') : t('add-to-wedding-list')}
							</Button>
						</> : ''}
					</div>
				</Modal>
			</div>
		</>
	)
}
