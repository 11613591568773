import React, { useContext, useEffect, useState } from 'react';
import { SessionContext } from '../providers/session';
import { makeStyles } from '@material-ui/styles';
import { Container } from '@material-ui/core';
import Link from 'next/link';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import theme from '../theme';
import urlNormalizer from 'tools/urlNormalization';
import { useRouter } from 'next/router';
import { TrackingContext } from '../providers/tracking';

const useStyles = makeStyles(
	() => {
		return {
			wrapper: {
				position: 'relative',
				width: '100%',
				overflow: 'hidden',
				minHeight: 34,
				backgroundColor: theme.palette.primary.themeColor,
			},
			link: {
				display: 'flex',
				justifyContent: 'center',
				height: 34,
				padding: '8px 0',
				textDecoration: 'none'
			},
			text: {
				position: 'absolute',
				top: '50%',
				left: 0,
				right: 0,
				margin: 0,
				fontSize: 12,
				fontWeight: 500,
				color: theme.palette.primary.background,
				textAlign: 'center',
				transform: 'translateY(-50px)',
				transition: 'transform 0.25s ease'
			},
			current: {
				display: 'block',
				transform: 'translateY(-50%)'
			},
			next: {
				transform: 'translateY(50px)',
				transition: 'none'
			}
		};
	},
	{ name: 'TopLine' }
);

export default function TopLine() {
	const classes = useStyles();
	const router = useRouter();
	const isFullwidth = useMediaQuery(theme.breakpoints.up('lg'));
	const { salesChannel } = useContext(SessionContext);
	const { actions: trackingActions } = useContext(TrackingContext);
	const [state, setState] = useState({ enabled: false, current: 1, next: 2 });
	const [countdown, setCountdown] = useState('');

	useEffect(() => {
		if (salesChannel.translated.customFields && getTopLine().altText && (!hasCountdown(getTopLine().altText) || hasCountdown(getTopLine().altText) && shouldShowCountdown(hasCountdown(getTopLine().altText)))) {
			setState(state => ({ ...state, enabled: true }));
			setInterval(() => {
				setState(({ enabled, current, next }) => {
					let newCurrent = next;
					setTimeout(() => {
						setState(({ enabled, current, next }) => {
							let newNext = current === 1 ? 2 : 1;
							return { enabled, current: current, next: newNext };
						});
					}, 300);
					return { enabled, current: newCurrent, next: 0 };
				});
			}, 3000);
		}

		if(getTopLine().text && (hasCountdown(getTopLine().altText) || hasCountdown(getTopLine().text))) {
			setInterval(() => {
				setCountdown(hasCountdown(getTopLine().text) ? prepareCountdown(getTopLine().text) : prepareCountdown(getTopLine().altText));
			}, [1000]);
		}
	}, [salesChannel]);

	function hasCountdown(text) {
		let dueDate = false;

		if(!text) {
			return false;
		}

		if(text.indexOf('[') >= 0 && text.indexOf(']') >= 0) {
			let date = text.substring(text.indexOf('[') + 1, text.indexOf(']'));
			dueDate = new Date(date).getTime();
		}

		return dueDate;
	}

	function prepareCountdown(text) {
		// [May 1, 2024 23:59:59]
		const dueDate = text.substring(text.indexOf('[') + 1, text.indexOf(']'));
		const dueTime = new Date(dueDate).getTime();
		const now = new Date().getTime();
		const distance = dueTime - now;
		const days = Math.floor(distance / (1000 * 60 * 60 * 24));
		let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

		if(days > 0) {
			hours = hours + (days * 24);
		}

		const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
		const seconds = Math.floor((distance % (1000 * 60)) / 1000);
		const cdTime = `${hours !== 0 ? hours + 't ' : ''}${minutes !== 0 ? minutes + 'm' : ''}`

		return cdTime;
	}

	function hasTopLine() {
		const text =
			salesChannel && salesChannel.translated.customFields
				? salesChannel.translated.customFields.topline_text
				: '';

		return !!text;
	}

	function hasTopLink() {
		const link =
			salesChannel && salesChannel.translated.customFields
				? salesChannel.translated.customFields.topline_link
				: '';

		return !!link;
	}

	function getTopLine() {
		const link =
			salesChannel && salesChannel.translated.customFields
				? salesChannel.translated.customFields.topline_link
				: '';
		const text =
			salesChannel && salesChannel.translated.customFields
				? salesChannel.translated.customFields.topline_text
				: '';
		const altText =
			salesChannel && salesChannel.translated.customFields
				? salesChannel.translated.customFields.topline_text_alt
				: false;
		const textColor =
			salesChannel && salesChannel.translated.customFields
				? salesChannel.translated.customFields.topline_text_color
				: '#000000';
		const bg =
			salesChannel && salesChannel.translated.customFields
				? salesChannel.translated.customFields.topline_color
				: '';

		return {
			link: link,
			text: text,
			altText: altText,
			textColor: textColor,
			background: bg
		};
	}

	function urlNormalize(url) {
		return urlNormalizer(url, router.basePath);
	}

	function shouldShowCountdown(countdown) {
		let now = new Date();
		let in36Hours = new Date((now.getTime() + 36 * 60 * 60 * 1000));
		let countdownDate = new Date(countdown);

		if(now < countdownDate && in36Hours > countdownDate) {
			return true;
		}

		return false;
	}

	function renderTopBar() {
		let text = getTopLine().text;
		if(hasCountdown(text) && shouldShowCountdown(hasCountdown(text))) {
			text = text.split('[')[0] + '<strong>' + countdown + '</strong>' + text.split(']')[1];
		}

		let altText = getTopLine().altText;
		if(altText && hasCountdown(altText) && shouldShowCountdown(hasCountdown(altText))) {
			altText = altText.split('[')[0] + '<strong>' + countdown + '</strong>' + altText.split(']')[1];
		}

		return (
			<Container disableGutters={isFullwidth}>
				<p
					className={[
						classes.text,
						!state.enabled || (state.enabled && state.current === 1) ? classes.current : '',
						state.enabled && state.next === 1 ? classes.next : ''
					].join(' ')}
					style={{ color: getTopLine().textColor }}
					dangerouslySetInnerHTML={{ __html: text}}
				/>
				{altText && (!hasCountdown(altText) || hasCountdown(altText) && shouldShowCountdown(hasCountdown(altText))) ? (
					<p
						className={[
							classes.text,
							state.enabled && state.current === 2 ? classes.current : '',
							state.enabled && state.next === 2 ? classes.next : ''
						].join(' ')}
						style={{ color: getTopLine().textColor }}
						dangerouslySetInnerHTML={{ __html: altText }}
					/>
				) : (
					''
				)}
			</Container>
		);
	}

	return (
		<div
			className={[classes.wrapper, salesChannel ? 'loaded' : ''].join(' ')}
			style={{ backgroundColor: getTopLine().background }}
		>
			{hasTopLine() && hasTopLink() ? (
				<Link href={urlNormalize(getTopLine().link)}>
					<a
						className={classes.link}
						onClick={() =>
							trackingActions.click({
								type: 'top_line',
								text: getTopLine().text,
								identifier: urlNormalize(getTopLine().link)
							})
						}
					>
						{renderTopBar()}
					</a>
				</Link>
			) : hasTopLine().text !== '' ? (
				renderTopBar()
			) : (
				''
			)}
		</div>
	);
}
