import React, {memo, useContext} from 'react';
import Link from 'next/link';
import urlCorrection from "../../../tools/urlCorrection";
import {TrackingContext} from "../../providers/tracking";

function MenuItem({children, item, level, classes, open, onHover, onLeave, label}) {
	const {actions: trackingActions} = useContext(TrackingContext);

	function renderList(children, level) {
		if (children && children.length > 0 && level > 1) {
			return (
				<ul className={[classes.subList, 'level-' + level].join(' ')}>
					{children}
				</ul>
			);
		} else {
			return ('');
		}
	}

	function returnChildren(){
		onHover(item.children);
	}

	function getItemId() {
		let urlArray = item.url.split('/');

		return urlArray[urlArray.length - 1];
	}

	const liEvents = level === 1 ? {onMouseEnter: returnChildren, onMouseLeave: onLeave} : {};

	return (
		<li className={[classes.item, open ? 'opened' : '', 'level-' + level, getItemId()].join(' ')} {...liEvents}>
			{item.type === 'folder' ? <span>{item.name}</span> :
				<Link href={urlCorrection(item.url)}>
					<a onClick={() => trackingActions.click({type: 'menu_link', text: label || item.name, identifier: urlCorrection(item.url)})}>
						{label ? <label style={{cursor: 'pointer'}}>{label}</label> : item.name}
					</a>
				</Link>}

			{renderList(children, level)}
		</li>
	);
}

export default memo(MenuItem);
