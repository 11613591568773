import { makeStyles } from '@material-ui/styles';
import theme from '../../../theme';
import { useContext, useEffect, useState } from 'react';
import { SessionContext } from '../../../providers/session';

const useStyles = makeStyles({
    labels: {
        position: 'absolute',
        top: 0,
        right: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        gap: 10,
        [theme.breakpoints.up('md')]: {
            top: 0,
        },
        '&.large': {
            top: 10,
            right: 10,
        },
    },
    marketingLabel: {
        background: '#000',
        color: '#fff',
        padding: '4px 10px'
    },
    discountBubble: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '2px 10px 1px 10px',
        whiteSpace: 'nowrap',
        fontSize: 10,
        fontWeight: 600,
        lineHeight: '22px',
        background: '#fff',
        color: '#000',
        pointerEvents: 'none',
        '&.large': {
            fontSize: 13,
            padding: '8px 20px'
        },
        '.xmas &': {
            backgroundColor: '#000',
            color: '#fff'
        },
        '.blackfriday &': {
            backgroundColor: '#000',
            color: '#fff'
        },
    },
});

export default function ProductLabels(
    {
        available,
        streamIds = [],
        prices,
        large = false,
        marketingLabels = null,
    }
) {
    const session = useContext(SessionContext);
    const classes = useStyles();
    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
        // Set to true only when the component has mounted (client-side)
        setIsClient(true);
    }, []);

    if (!isClient) return null; // Don't render on the server

    const size = large ? 'large' : 'small';
    const preAccess = session.isSaleOngoing;
    function renderStreamIds(productStreamIds) {
        if (!preAccess) {
            return null
        }

        if (!Array.isArray(productStreamIds) || !(productStreamIds.length > 0)) {
            return null;
        }

        const labels = {
            '01931b126f317ed5965082b389598a6a': !preAccess ? `SUPER DEAL 70%` : 'PRE-ACCESS 70%',
            '01931b1561be719cb5a9f0f94c40f6d3': !preAccess ? `CHRISTMAS DEAL 50%` : 'PRE-ACCESS 50%',
            '01931babf0667a4c8da1d8e9fde47ab6': !preAccess ? `BLACK WEEK 20%` : 'PRE-ACCESS 20%',
        };

        function renderLabel(label) {
            return <span key={'disco-bubble'} className={[ classes.discountBubble, 'discountBubble', 'black-week', size ].join(' ')}>
                {label}
            </span>;
        }

        if (!session.actions.isSalesChannelNordic()) {
            return null;
        }

        if (prices.discount) {
            return null;
        }

        if (!available) {
            return null;
        }

        if (productStreamIds.includes('01931b126f317ed5965082b389598a6a')) { // 70%
            return renderLabel(labels['01931b126f317ed5965082b389598a6a']);
        }

        if (productStreamIds.includes('01931b1561be719cb5a9f0f94c40f6d3')) { // 50%
            return renderLabel(labels['01931b1561be719cb5a9f0f94c40f6d3']);
        }

        if (productStreamIds.includes('01931babf0667a4c8da1d8e9fde47ab6')) { // 20%
            return renderLabel(labels['01931babf0667a4c8da1d8e9fde47ab6']);
        }

        return null;
    }

    let labels = [];
    if (marketingLabels?.length) {
        labels = marketingLabels.map(({ id, label }) => <span
            key={`${label}-${id}`}
            className={[ classes.marketingLabel ].join(' ')}
        >
            {label}
        </span>);
    }

    if (prices.discount) {
        labels.push(<span className={[classes.discountBubble].join(' ')}>{Math.floor(prices.discount.percentage)}%</span>);
    }

    labels.push(renderStreamIds(streamIds));
    if (labels?.length <= 0) {
        return null;
    }

    return (<div className={[ classes.labels, size ].join(' ')}>
        {labels}
    </div>);
}
